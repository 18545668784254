.nachrichten-show {
  background-color: white;
  border-radius: 10px;
  padding: 1rem 2rem;
  color: black;
  border: 0.5px solid #949494;
  -webkit-box-shadow: 0px 0px 4px 0px #7e7e7e;
  box-shadow: 0px 0px 4px 0px #7e7e7e;
}

.nachrichten-show .header {
  font-weight: 200;
}

.chat-container {
  border-radius: 20px;
}

.chatbox {
  padding: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  border-radius: 15px;
}

.chatbox-left {
  margin-right: 50%;
  width: 50%;
  text-align: left;
  background-color: white;
}

.chatbox-right {
  margin-left: 50%;
  width: 50%;
  text-align: left;
  background-color: lightskyblue;
}

.chatbox-timestamp {
  font-weight: 200;
  font-size: 0.8rem;
}

.chatbox-userstamp {
  font-weight: 400;
  font-size: 0.8rem;
}

.message-container {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.send-icon {
  color: white;
  min-height: 50px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: rgb(0, 132, 255);
  border-radius: 0px 10px 10px 0px;
}

#chat-history {
  display: flex;
  align-items: flex-end;
  flex-flow: column-reverse;
  height: 600px;
  overflow: auto;
  background-color: rgb(243, 243, 243);
  padding: 1.5rem;
  border-radius: 10px 10px 0px 0px;
}

#chat-new-message {
  min-height: 50px;
  background-color: rgb(201, 201, 201);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 10px 10px 10px;
}

#message-input {
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  border-radius: 10px 0px 0px 10px;
}

#message-input:active,
#message-input:focus {
  outline: none;
}
