.verifcode-box {
  background-color: white;
  border-radius: 10px;
  padding: 1rem 2rem;
  color: black;
  border: 0.5px solid #949494;
  -webkit-box-shadow: 0px 0px 4px 0px #7e7e7e;
  box-shadow: 0px 0px 4px 0px #7e7e7e;
}

.verifcode-box .header {
  font-weight: 200;
}

.verifcode-box .error-box {
  text-align: center;
  background-color: #ffffff;
  color: #A61C1C;
  padding: 15px 5px;
  margin: 15px 5px;
  border-radius: 10px;
  border: 0.4px solid #A61C1C;
  -webkit-box-shadow: 1px 3px 10px .5px rgba(0,0,0,0.2); 
  box-shadow: 1px 3px 10px .5px #a61c1c56;

}

.error-box p {
  margin: 0;
}