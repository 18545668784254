.login-container {
  height: 95%;
  display: flex;
  align-items: center;
}

.smile-logo {
  margin: 2rem 3rem;
}

.select-prompt {
  text-align: center;
  width: 100%;
}

.login-form-container {
  text-align: center;
  width: 100%;
}

.register-form-container {
  margin-top: 0%;
  text-align: center;
  width: 100%;
}

.login-form {
  width: 80%;
  margin: 10px auto;
  text-align: center;
}

.form-header {
  font-weight: 200;
  margin: 20px;
}

.goback-div {
  width: 60%;
  margin: 50px auto;
  text-align: left;
}

.goback-button {
  border: none;
  font-size: 1.5rem;
  font-weight: 100;
  color: gray;
}

.select-button {
  background-color: transparent;
  color: rgb(94, 94, 94);
  border: 1px solid black;
  border-radius: 5px;
  padding: 15px 25px;
  margin: 20px 40px;
  width: 150px;
}

.login-footer {
  text-align: center;
}

.login-button {
  background-color: transparent;
  color: rgb(94, 94, 94);
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px 35px;
}

.prevnext-button {
  padding: 8px 25px;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #636363; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.submit-error {
  background: #ff8e8e42;
  border: 1px solid #ff8484;
  border-radius: 10px;
  padding: 5px 15px;
  color: black;
}
.submit-error h4 {
  font-weight: 200;
}

.submit-error p {
  font-weight: 200;
}

@media (min-width: 1200px) {
  .login-form {
    width: 80%;
  }
}
@media (min-width: 1500px) {
  .login-form {
    width: 80%;
  }
}
@media (min-width: 1700px) {
  .login-form {
    width: 60%;
  }
}
