.patienten-list {
  background-color: white;
  border-radius: 10px;
  height: 100%;
  padding: 1rem 2rem;
  color: black;
  border: 0.5px solid #949494;
  -webkit-box-shadow: 0px 0px 4px 0px #7e7e7e;
  box-shadow: 0px 0px 4px 0px #7e7e7e;
}

.patienten-list .header {
  font-weight: 200;
  display: inline-block;
  margin-top: 10px;
}

#addPatientBtn {
  float: right;
  margin-bottom: 25px;
  margin-top: 10px;
}

#header-table {
  width: 100%;
  background-color: #313131;
  color: white;
  padding: 30px;
  border-radius: 10px 10px 0px 0px;
}

#header-table th, #header-table td {
  padding: 15px;
}

#body-table {
  width: 100%;
}

#body-table td {
  padding: 15px;
}

th,
td {
  text-align: left;
}

#body-table tr:nth-child(even) {
  background-color: whitesmoke;
}

#table-container {
  height: 700px;
  overflow: auto;
}

td.unread {
  font-weight: 600;
}

tr.finished {
  color: #7e7e7e;
}

tr.finished .btn {
  background-color: rgb(140, 140, 255);
  border-color: rgb(140, 140, 255);
}