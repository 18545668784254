.einstellungen-box {
  background-color: white;
  border-radius: 10px;
  padding: 1rem 2rem;
  color: black;
  border: 0.5px solid #949494;
  -webkit-box-shadow: 0px 0px 4px 0px #7e7e7e;
  box-shadow: 0px 0px 4px 0px #7e7e7e;
}

.einstellungen-box .header {
  font-weight: 200;
}

.hint {
  font-size: 0.8em;
  font-weight: 300;
}
