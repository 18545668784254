.nachrichten-list {
  background-color: white;
  border-radius: 10px;
  height: 100%;
  padding: 1rem 2rem;
  color: black;
  border: 0.5px solid #949494;
  -webkit-box-shadow: 0px 0px 4px 0px #7e7e7e;
  box-shadow: 0px 0px 4px 0px #7e7e7e;
}

.nachrichten-list .header {
  font-weight: 200;
  display: inline-block;
  margin-top: 10px;
}

#header-table {
  width: 100%;
  background-color: #313131;
  color: white;
  padding: 30px;
  border-radius: 10px 10px 0px 0px;
}

#header-table th {
  padding: 15px;
}

#body-table {
  width: 100%;
}

#body-table td {
  padding: 15px;
}

th,
td {
  text-align: left;
}

#body-table tr:nth-child(even) {
  background-color: whitesmoke;
}

#table-container {
  height: 700px;
  overflow: auto;
}

.bold {
  font-weight: bold;
}

.unread-icon {
  color: rgb(82, 82, 82);
}
