#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  position: relative;
  background-color: rgb(235, 235, 235);
  border-radius: 0px 25px 25px 0px;
  -moz-box-shadow: -3px 0px 10px 0px #0e0e0e;
  -webkit-box-shadow: -3px 0px 10px 0px #0e0e0e;
  box-shadow: -3px 0px 10px 0px #0e0e0e;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 20rem;
}

#page-content-wrapper {
  overflow-y: auto;
  height: 100vh;
  background-image: url(./background.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#sidebar-wrapper {
  margin-left: 0;
}

#page-content-wrapper {
  width: 100%;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: -20rem;
}

@media (min-width: 768px) {
}

#sidebar-heading {
  padding: 22px;
  width: 300px;
}

#sidebar-logo {
  width: 100%;
}

#wrapper {
  background-color: #c2c2c2;
}

.sidebar-list-item {
  color: black;
  padding: 20px 20px;
  background-color: rgb(235, 235, 235);
  transition: all linear 0.1s;
}

.sidebar-list-item:not(.active):hover {
  text-decoration: none;
  color: black;
  background-color: rgb(220, 220, 220);
  border-left: 5px solid rgb(99, 0, 0);
}
.sidebar-list-item[data-active="true"] {
  border-left: 5px solid rgb(146, 5, 5);
  background-color: rgb(215, 215, 215);
}

.sidebar-footer {
  background-color: #313131;
  font-weight: 200;
  color: white;
  height: 7vh;
  border-bottom: 1px solid #808080;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border: 1px solid #58585873;
  border-radius: 0px 0px 25px 0px;
}

.footer-user {
  font-weight: 400;
}

.logout-button {
  text-align: center;
  transition: all linear 0.1s;
}

.logout-button:hover {
  color: rgb(146, 5, 5);
  cursor: pointer;
}

.unread-messages-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}