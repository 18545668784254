.user-patients thead th.left {
    padding: 10px 15px;
    background-color: #4c4c4c;
    border-radius: 10px 0px 0px 0px;
    color: white;
}
.user-patients thead th.right {
    padding: 10px 15px;
    background-color: #4c4c4c;
    border-radius: 0px 10px 0px 0px;
    color: white;
}
.user-patients tbody td {
    padding: 10px 15px;
}
.user-patients tbody tr:nth-child(even) td {
    background-color: whitesmoke;
}