.einstellungen-box .error-box {
  text-align: center;
  background-color: #ffffff;
  color: #a61c1c;
  padding: 15px 5px;
  margin: 15px 5px;
  border-radius: 10px;
  border: 0.4px solid #a61c1c;
  -webkit-box-shadow: 1px 3px 10px 0.5px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 3px 10px 0.5px #a61c1c56;
}

.error-box p {
  margin: 0;
}
