#patientenTableContainer {
  margin-top: 2rem;
  background-color: white;
  min-width: 800px;
}
#patientenTableContainer .header {
  color: black;
  display: block;
  margin: 1rem 0rem;
}
