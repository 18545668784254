.patienten-info {
  background-color: white;
  border-radius: 10px;
  padding: 1rem 2rem;
  color: black;
  border: 0.5px solid #949494;
  -webkit-box-shadow: 0px 0px 4px 0px #7e7e7e;
  box-shadow: 0px 0px 4px 0px #7e7e7e;
}

.patienten-info .header {
  font-weight: 200;
  display: inline-block;
  margin-top: 10px;
}

.infodot-green {
  height: 25px;
  width: 25px;
  background-color: rgb(0, 167, 0);
  display: inline-block;
  margin: 0rem 1rem;
  border-radius: 25px;
}

.infodot-red {
  height: 25px;
  width: 25px;
  background-color: rgb(167, 0, 0);
  display: inline-block;
  margin: 0rem 1rem;
  border-radius: 25px;
}

#info-verlauf {
  height: 200px;
  overflow: auto;
}

.datei-header {
  text-align: center;
  font-weight: 200;
}

.datei-thumb {
  background: whitesmoke;
  padding: 10px;
  border-radius: 20px;
  margin: 5px 0px;
}

.datei-thumb img {
  max-height: 300px;
  display: inline-block;
}

.thumb-icon {
  font-size: 1.5em;
}

.thumb-icon small {
  font-weight: 300;
  font-size: 0.6em;
}
.thumb-icon .download {
  color: lightskyblue;
  cursor: pointer;
}

.thumb-icon .verified {
  color: rgb(8, 201, 2);
}

.thumb-icon .checking {
  color: rgb(0, 94, 153);
}

.thumb-icon .wrong {
  color: rgb(136, 0, 0);
}

.tooltip {
  z-index: 10;
  font-size: 15px!important;
}