.loading-modal .modal-content{
    background-color: rgba(240, 240, 240, 0.781);
  }
  
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }
  
  .top-loading-container {
    width: 30%;
  }
  
  .top-loading-container img {
    width: 100%;
  }
  
  .bottom-loading-container {
    width: 90%;
  }
  
  .bottom-loading-container h4 {
    font-size: 2rem;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 200;
  
  }
  