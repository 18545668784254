.square-header {
  font-size: 1.5rem;
  font-weight: 200;
}

.square-content {
  text-align: right;
  font-size: 2rem;
}

#offene {
  background-color: #2ecc71;
}

#abgeschlossene {
  background-color: #e67e22;
}

#insgesamt {
  background-color: #2980b9;
}
