.App {
  min-height: 100vh;
  width: 100%;
  display: flex;
}

.left-container {
  background-image: url(./login-background.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 10;
}

.right-container {
  background-color: rgb(235, 235, 235);
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
    0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.main-row {
  width: 100%;
}

.slider-right-enter {
  position: relative;
  z-index: 1;
  opacity: 0;
  transform: translate(-500px, 0);
  transform: translate3d(-500px, 0, 0);
}
.slider-right-enter.slider-right-enter-active {
  opacity: 1;
  transition: opacity 2s ease;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}
.slider-right-exit {
  position: absolute;
  z-index: -100;
  opacity: 1;
  transform: translate(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}
.slider-right-exit.slider-right-exit-active {
  opacity: 0;
  transform: translate(500px, 0);
  transform: translate3d(500px, 0, 0);
  display: none;
}

.slider-left-enter {
  position: relative;
  z-index: 1;
  opacity: 0;
  transform: translate(500px, 0);
  transform: translate3d(500px, 0, 0);
}
.slider-left-enter.slider-left-enter-active {
  opacity: 1;
  transition: opacity 2s ease;
  transform: translate(0, 0);
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}
.slider-left-exit {
  position: absolute;
  z-index: -100;
  opacity: 1;
  transform: translate(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition-property: transform, opacity;
  transition-duration: 1000ms;
  transition-timing-function: cubic-bezier(0.175, 0.665, 0.32, 1), linear;
}
.slider-left-exit.slider-left-exit-active {
  opacity: 0;
  transform: translate(-500px, 0);
  transform: translate3d(-500px, 0, 0);
  display: none;
}
