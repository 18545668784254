.patienten-step {
  background-color: white;
  border-radius: 10px;
  padding: 1rem 2rem;
  color: black;
  border: 0.5px solid #949494;
  -webkit-box-shadow: 0px 0px 4px 0px #7e7e7e;
  box-shadow: 0px 0px 4px 0px #7e7e7e;
}

.header {
  font-weight: 200;
}

.patienten-step.step1 {
  width: 70rem;
}

.new-patient-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.condition-label {
  background-color: rgb(247, 247, 247);
  border-radius: 10px;
}

.bg-hint-alert {
  background-color: #af2e2e;
  color: #ffffff;
  border-radius: 10px;
}