.widget-square {
  background-color: black;
  border-radius: 10px;
  margin: 0.1rem;
  padding: 1rem 2rem;
  color: white;
  height: 100%;
  border: 0.5px solid #949494;
  -webkit-box-shadow: 0px 0px 4px 0px #7e7e7e;
  box-shadow: 0px 0px 4px 0px #7e7e7e;
}
