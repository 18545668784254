#smile-animation-logo-container {
  position: absolute;
  height: 150px;
  top: 0px;
  left: 0px;
  padding: 1rem;
}

#smile-animation-logo {
  border-top: 2px solid black;
  border-left: 2px solid black;
  border-right: 2px solid black;
  padding: 0.5rem;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgb(238, 238, 238);
  top: 0px;
}

#smile-animation-logo img {
  height: 100%;
  width: auto;
}
