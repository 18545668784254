.ampel-item {
    margin: 10px 0px;
    display: flex;
    align-items: center;
}

.ampel-item .ampel-green {
    display: inline-block;
    margin: 0px 10px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: rgb(0, 167, 0);
}

.ampel-item .ampel-red {
    display: inline-block;
    margin: 0 10px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: rgb(165, 0, 0);
}